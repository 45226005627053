/**
 *  C-World 4 - Icon-Font Definition
 *  ====================================
 *
 *  @details    Klassendefinitionen für die CW4 Icon-Font.
 *              Wenn neue Icons hinzugefügt werden dürfen nur neue Klassendefinitionen
 *              hinzugefügt werden oder bestehende von Hand angepasst. Auf keinen Fall
 *              dürfen einfach alle/mehrere Definitionen auf einmal per Copy/Paste
 *              ausgetauscht werden.
 *
 */

@font-face {
    font-family: 'cworldfont';
    src: url('~/assets/fonts/cworldfont.eot');
    src:
        url('~/assets/fonts/cworldfont.eot') format('embedded-opentype'),
        url('~/assets/fonts/cworldfont.ttf') format('truetype'),
        url('~/assets/fonts/cworldfont.woff') format('woff'),
        url('~/assets/fonts/cworldfont.svg#cworldfont') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'cworldfont' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-list-tree-solid:before {
    content: '\e980';
}
.icon-arrow-down-from-line-solid:before {
    content: '\e97f';
}
.icon-expand-solid:before {
    content: '\e97e';
}
.icon-calendar-plus:before {
    content: '\e97d';
}
.icon-users-class:before {
    content: '\e97c';
}
.icon-table:before {
    content: '\e97b';
}
.icon-reverse-sort:before {
    content: '\e97a';
}
.icon-file-exclamation:before {
    content: '\e977';
}
.icon-file-alt:before {
    content: '\e978';
}
.icon-file-check:before {
    content: '\e979';
}
.icon-percent:before {
    content: '\e976';
}
.icon-arrow-to-bottom:before {
    content: '\e975';
}
.icon-upload:before {
    content: '\e972';
}
.icon-trophy:before {
    content: '\e96f';
}
.icon-search-plus:before {
    content: '\e96e';
}
.icon-mail-bulk:before {
    content: '\e96d';
}
.icon-chevron-double-up:before {
    content: '\e96b';
}
.icon-chevron-double-down:before {
    content: '\e96c';
}
.icon-link:before {
    content: '\e968';
}
.icon-level-down-alt:before {
    content: '\e969';
}
.icon-level-up-alt:before {
    content: '\e96a';
}
.icon-envelope:before {
    content: '\e965';
}
.icon-vials:before {
    content: '\e966';
}
.icon-user-check:before {
    content: '\e967';
}
.icon-remote:before,
.icon-video:before {
    content: '\e964';
}
.icon-sort-down-alt:before {
    content: '\e962';
}
.icon-sort-up-alt:before {
    content: '\e963';
}
.icon-menu-dots:before {
    content: '\e960';
}
.icon-menu-dots-horizontal:before {
    content: '\e961';
}
.icon-heart-empty:before {
    content: '\e95e';
}
.icon-heart:before {
    content: '\e95f';
}
.icon-institutions:before {
    content: '\e95c';
    color: rgb(255, 0, 19);
}
.icon-institutions:after {
    content: '\e95d';
    margin-left: -1em;
    color: rgb(0, 0, 0);
}
.icon-chart-pie:before {
    content: '\e95b';
}
.icon-hand-pointer:before {
    content: '\e959';
}
.icon-at:before {
    content: '\e95a';
}
.icon-calendar-check:before {
    content: '\e958';
}
.icon-buffer-brands:before {
    content: '\e957';
}
.icon-location-search:before {
    content: '\e956';
}
.icon-calendar-week:before {
    content: '\e954';
}
.icon-calendar-day:before {
    content: '\e955';
}
.icon-arrow-circle-down:before {
    content: '\e950';
}
.icon-arrow-circle-left:before {
    content: '\e951';
}
.icon-arrow-circle-right:before {
    content: '\e952';
}
.icon-arrow-circle-up:before {
    content: '\e953';
}
.icon-institution-apothecary-branch:before {
    content: '\e94f';
    color: #ff0013;
}
.icon-radar:before {
    content: '\e94b';
}
.icon-phone:before {
    content: '\e94c';
}
.icon-fax:before {
    content: '\e94d';
}
.icon-close-circle:before {
    content: '\e94e';
}
.icon-star-empty:before {
    content: '\e949';
}
.icon-star:before {
    content: '\e94a';
}
.icon-vacation:before {
    content: '\e928';
}
.icon-parking:before {
    content: '\e948';
}
.icon-budget-overview:before {
    content: '\e944';
}
.icon-budget-allocation:before {
    content: '\e945';
}
.icon-products:before,
.icon-samples-overview:before {
    content: '\e946';
}
.icon-box-open:before,
.icon-samples-inventory:before {
    content: '\e947';
}
.icon-quill-editor-redo:before {
    content: '\e943';
}
.icon-quill-editor-undo:before {
    content: '\e942';
}
.icon-minus-circle-solid:before {
    content: '\e940';
}
.icon-minus-circle:before {
    content: '\e941';
}
.icon-plus-circle-solid:before {
    content: '\e93f';
}
.icon-plus-circle:before {
    content: '\e93e';
}
.icon-list:before {
    content: '\e93d';
}
.icon-broom:before {
    content: '\e93c';
}
.icon-help:before {
    content: '\e93b';
}
.icon-envelope-open:before {
    content: '\e93a';
}
.icon-user-injured:before {
    content: '\e92c';
}
.icon-user-lock:before {
    content: '\e92d';
}
.icon-user-shield:before {
    content: '\e92e';
}
.icon-users-cog:before {
    content: '\e92f';
}
.icon-user-cog:before {
    content: '\e930';
}
.icon-tasks:before {
    content: '\e931';
}
.icon-project-diagram:before {
    content: '\e932';
}
.icon-lock-open:before {
    content: '\e933';
}
.icon-lock:before {
    content: '\e934';
}
.icon-key:before {
    content: '\e935';
}
.icon-diagnoses:before {
    content: '\e936';
}
.icon-code-branch:before {
    content: '\e937';
}
.icon-briefcase-medical:before {
    content: '\e938';
}
.icon-procedures:before {
    content: '\e939';
}
.icon-play:before {
    content: '\e92a';
}
.icon-bookmark:before {
    content: '\e92b';
}
.icon-delete:before {
    content: '\e929';
}
.icon-regions:before {
    content: '\e926';
}
.icon-lines:before {
    content: '\e927';
}
.icon-clone:before {
    content: '\e925';
}
.icon-invoices:before {
    content: '\e922';
}
.icon-orders:before,
.icon-orders-overview:before {
    content: '\e923';
}
.icon-expenses:before {
    content: '\e924';
}
.icon-map-marker:before {
    content: '\e90c';
}
.icon-route:before {
    content: '\e90f';
}
.icon-map-marked:before {
    content: '\e921';
}
.icon-bell:before {
    content: '\e90a';
}
.icon-institution-red:before,
.icon-institution-wound:before {
    content: '\e90b';
    color: #ff5436;
}
.icon-institution-yellow:before,
.icon-institution-seniorcare:before {
    content: '\e90d';
    color: #eac000;
}
.icon-institution-red-cross:before {
    content: '\e90e';
    color: #f00;
}
.icon-institution-laboratory:before {
    content: '\e910';
    color: rgb(0, 92, 255);
}
.icon-institution-laboratory:after {
    content: '\e911';
    margin-left: -1em;
}
.icon-institution-inhouse-apothecary:before {
    content: '\e919';
}
.icon-institution-inhouse-apothecary:after {
    content: '\e914';
    margin-left: -1em;
    color: rgb(255, 0, 19);
}
.icon-institution-industry:before {
    content: '\e915';
}
.icon-institution-light-green:before,
.icon-institution-hygiene:before {
    content: '\e916';
    color: #00c6a1;
}
.icon-houses:before {
    content: '\e917';
}
.icon-institution-blue:before,
.icon-institution-desinfection:before {
    content: '\e918';
    color: #0071ea;
}
.icon-institution:before {
    content: '\e919';
}
.icon-institution-clinic:before {
    content: '\e919';
}
.icon-institution-clinic:after {
    content: '\e91a';
    margin-left: -1em;
    color: rgb(255, 0, 0);
}
.icon-institution-care:before {
    content: '\e91d';
}
.icon-beds:before {
    content: '\e91e';
}
.icon-bed:before {
    content: '\e91f';
}
.icon-institution-apothecary:before {
    content: '\e920';
    color: #ff0013;
}
.icon-warning-sign:before {
    content: '\f071';
}
.icon-complaint:before {
    content: '\e909';
}
.icon-chevron-left:before {
    content: '\f053';
}
.icon-chevron-right:before {
    content: '\f054';
}
.icon-user-md:before {
    content: '\f0f0';
}
.icon-search:before {
    content: '\f002';
}
.icon-user:before {
    content: '\f007';
}
.icon-check:before {
    content: '\f00c';
}
.icon-close:before {
    content: '\f00d';
}
.icon-logoff:before {
    content: '\f011';
}
.icon-clock:before {
    content: '\f017';
}
/*.icon-lock:before {
  content: "\f023";
}*/
.icon-print:before {
    content: '\f02f';
}
.icon-edit:before {
    content: '\f040';
}
.icon-info-circle:before {
    content: '\f05a';
}
.icon-arrow-up:before {
    content: '\f062';
}
.icon-arrow-down:before {
    content: '\f063';
}
.icon-expand:before {
    content: '\f065';
}
.icon-compress:before {
    content: '\f066';
}
.icon-plus:before {
    content: '\f067';
}
.icon-minus:before {
    content: '\f068';
}
.icon-asterisk:before {
    content: '\f069';
}
.icon-calendar:before {
    content: '\f073';
}
.icon-chevron-up:before {
    content: '\f077';
}
.icon-chevron-down:before {
    content: '\f078';
}
.icon-extend:before {
    content: '\f07e';
}
.icon-dashboard:before {
    content: '\f080';
}
.icon-cogs:before {
    content: '\f085';
}
.icon-comments:before {
    content: '\f086';
}
.icon-external-link:before {
    content: '\f08e';
}
.icon-filter:before {
    content: '\f0b0';
}
.icon-files:before {
    content: '\f0c5';
}
.icon-columns:before {
    content: '\f0db';
}
.icon-sort:before {
    content: '\f0dc';
}
.icon-sort-down:before {
    content: '\f0dd';
}
.icon-sort-up:before {
    content: '\f0de';
}
.icon-clipboard:before {
    content: '\f0ea';
}
.icon-spinner:before {
    content: '\f110';
}
.icon-shield:before {
    content: '\f132';
}
.icon-excel:before {
    content: '\f1c3';
}
.icon-history:before {
    content: '\f1da';
}
.icon-linechart:before {
    content: '\f201';
}
.icon-stickynote:before {
    content: '\f249';
}
.icon-creditcard:before {
    content: '\f283';
}
.icon-invoice:before {
    content: '\e906';
}
.icon-institutions-list:before {
    content: '\e900';
}
.icon-people-list:before {
    content: '\e901';
}
.icon-meetings-list:before {
    content: '\e904';
}
.icon-appointment:before {
    content: '\e908';
}
.icon-task:before {
    content: '\e907';
}
.icon-contact:before,
.icon-visit:before {
    content: '\e905';
}
.icon-selection:before {
    content: '\e902';
}
.icon-meeting:before {
    content: '\e903';
}
.icon-euro:before {
    content: '\e974';
}
.icon-plan-costs:before {
    content: '\e973';
}
.icon-up-down-solid:before {
    content: '\e981';
}
