@use '@angular/material' as mat;
/**
 *  C-World 4 - Theme DFKC
 *  ======================
 */

// Dunkelorange
$cw-theme: (
        50 : #fdf0e1,
        100 : #fad9b3,
        200 : #f7bf80,
        300 : #f3a54d,
        400 : #f19227,
        500 : #ee7f01,
        600 : #ec7701,
        700 : #e96c01,
        800 : #e76201,
        900 : #e24f00,
        A100 : #ffffff,
        A200 : #ffe2d6,
        A400 : #ffbda3,
        A700 : #ffab8a,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #000000,
                500 : #000000,
                600 : #000000,
                700 : #000000,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #000000,
        )
);

// Theme Definition

@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
// ($base-palette, $default: 500, $lighter: 100, $darker: 700)
$cworld-app-primary: mat.define-palette(mat.$gray-palette);
$cworld-app-accent:  mat.define-palette($cw-theme, 400, 200, 600);

// The warn palette is optional (defaults to red).
$cworld-app-warn:    mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$cworld-app-theme: mat.define-light-theme($cworld-app-primary, $cworld-app-accent, $cworld-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cworld-app-theme);

:root {
    --color-primary-light: #f0f0f0;
    --color-primary-lighter: #e2e2e2;
    --color-primary: #b2b2b2;
    --color-primary-darker: #9e9e9e;
    --color-primary-dark: #6b6b6b;
    --color-accent: #ee7f01; // 500
    --color-accent-light: #f7bf80; // 200
    --color-default-dark: #484848;
    --color-default-light: #fff;
}

/*
 * Dark Mode
 */
:root .darkmode {
    --color-primary-light: #6b6b6b;
    --color-primary-lighter: #9e9e9e;
    /*--color-primary: #b2b2b2;*/
    --color-primary-darker: #e2e2e2;
    --color-primary-dark: #f0f0f0;
    /*--color-accent: #164194;*/
    /*--color-accent-light: #6a85ba;*/
    --color-default-dark: #fff;
    --color-default-light: #484848;
}
